import React, {useState} from "react";
import PropTypes from "prop-types";
import {MetaTags} from '@ilc-technology/cefcom-metatags';
import { getNestedVals,  getFormattedDate } from "@ilc-technology/cefcom-utils";
import "../styles/main.scss";
import { Helmet } from "react-helmet";
import Header from '../components/custom/header/header.js'
import Footer from '../components/custom/footer/footer.js'
import CookieBanner from './../components/custom/CookieBanner/index.tsx'


const Layout = ({ children, pageContext }) => {
  const {centralData, slug, mc, story} = pageContext
  // *************************************************************
  // Slug vs currentUrl
  // Please note the "slug" includes also the siteRoot/rootFolder 
  // which might result in something like "/seo-minisites/cpf/" 
  // which is not what  usually we intend for slug expecting 
  // only "/slug/". In those cases please use the "currentUrl" below
  const currentUrl = `/${slug?.split('/').slice(2).join('/')}`;
  // *************************************************************
  // const siteRoot             =  getNestedVals( centralData, ['siteData','siteRoot'], '');
  const currentDate          = getFormattedDate()
  const menuLinks            = getNestedVals(centralData, ['siteData', 'menuLinks'] , null)
  const cookieBanner         = getNestedVals(centralData, ['footer', 'cookieBanner'] , null)
  const { bannerServiceLabels } = cookieBanner || {};
  const isTransparent        = getNestedVals(story, ['content', 'transparent_header'], false);
  let meta                   = getNestedVals(centralData, ['page', 'story', 'content'], '')
  // const gtmContainerId       = 'GTM-T53CLVL' // provided by Mani
  const inPageDb = getNestedVals(meta, ['inPageDb'] , '');
  const fullSlug = getNestedVals(meta, ['fullSlug'] , '');
  const [isCookieBannerOpened, setIsCookieBannerOpened] = useState(null)

  
  return <>
    <Helmet>
  
      <html 
        lang={mc} 
        inPageDb={inPageDb}
        data-build-date={currentDate} 
        data-master-english-pagename={fullSlug}
        className={ ` mkt-${mc} ${isTransparent ? ' transparent -top-gradient' : ''}`} />
      <link rel="icon" type="image/png" href={`/assetscdn/2j4dp9cib5zpddgmteai/cefcom-erika-insurance/shared-assets/erika-favicon.ico`} />
      {/* <script>{
        `(function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
        'gtm.start':
        new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        '//www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${gtmContainerId}');`
        }
      </script> */}
    </Helmet>
    <MetaTags metaData={{meta : {seoMetaContentResultSet: meta}}} />
    <Header menuLinks={menuLinks} mc={mc} currentUrl={currentUrl} />
    <div className="body">{children}</div>
    <CookieBanner labels={bannerServiceLabels} mc={mc} setIsCookieBannerOpened={setIsCookieBannerOpened}/>
    <Footer menuLinks={menuLinks} mc={mc} currentUrl={currentUrl} showBanner={isCookieBannerOpened}/>
  </>
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
